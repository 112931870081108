import React from 'react'
import styled from 'styled-components'
import { ButtonComponentStyles } from '../styles/GenericStyles'

const ContactStyled = styled.section`
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FormStyles = styled.form`
  width: 50%;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;

  label {
    input,
    textarea {
      display: block;
      width: 100%;
      background: inherit;
      color: var(--grey);
      border: none;
      border-bottom: 1px solid var(--darker-grey);
      padding: 0.4em;
    }

    textarea {
      border: 1px solid var(--darker-grey);
      height: 10em;
      margin-top: 0.4em;
    }
  }

  .note {
    margin-top: 0;
    text-align: right;
    font-size: 0.7em;
  }

  @media (max-width: 700px) {
    width: 95%;
  }
`

function Contact() {
  return (
    <ContactStyled id="contact">
      <FormStyles
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/thankyou"
      >
        <p>
          If you want to reach out to me, you can drop me a message on LinkedIn
          or send me an email using this form:
        </p>
        {/* this line is needed to make the netlify-form work */}
        <input type="hidden" name="form-name" value="contact" />
        <label htmlFor="email">
          {' '}
          Email:
          <input
            type="email"
            name="email"
            id="email"
            placeholder="your email"
            required
            maxLength="100"
          />
        </label>{' '}
        <label htmlFor="subject">
          Subject:
          <input
            type="text"
            name="subject"
            id="subject"
            placeholder="your subject"
            required
            maxLength="100"
          />
        </label>
        <label htmlFor="message">
          {' '}
          Message:
          <textarea
            name="message"
            id="message"
            required
            maxLength="1000"
            placeholder="adding a little joke to your message can result in lower response times 😉"
          />
        </label>
        <ButtonComponentStyles deg="rotate(-4deg)" type="submit">
          send with post-carriage 🐴
        </ButtonComponentStyles>
        <ButtonComponentStyles deg="rotate(3deg)" type="submit">
          send with owl mail* 🦉{' '}
        </ButtonComponentStyles>
        <p className="note">*required in case of oversea delivery</p>
      </FormStyles>
    </ContactStyled>
  )
}

export default Contact
