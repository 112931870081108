import React from 'react'
import styled from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { ButtonComponentStyles } from '../styles/GenericStyles'

const WelcomeStyled = styled.section`
  min-height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
    img {
      border-radius: 50%;
      width: 100%;
      object-fit: cover;
    }

    .presentation-text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      line-height: 1.5;
    }
  }

  @media (max-width: 1150px) {
    margin: 0 2em;
  }

  @media (max-width: 768px) {
    text-align: center;
    .grid-container {
      grid-template-columns: 1fr;

      img {
        border-radius: 0%;
        object-position: top;
      }
    }
  }
`

const ButtonWithMargin = styled(ButtonComponentStyles)`
  margin: 0.5em;
`

function Welcome() {
  const data = useStaticQuery(graphql`
    query {
      sanityPicture(id: { eq: "-d9153fb6-3f78-57cc-ace8-d36e74def822" }) {
        altText
        picture {
          asset {
            fluid(maxWidth: 800, maxHeight: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)
  return (
    <WelcomeStyled id="welcome">
      <div className="grid-container">
        <div className="presentation-text">
          <h2>
            Hi! I am Elisabeth. <br />I build web apps with React and the cloud.
          </h2>
          <div>
            <ButtonWithMargin
              type="button"
              onClick={() => {
                scrollTo('#projects')
              }}
            >
              see my work
            </ButtonWithMargin>
            <ButtonWithMargin
              deg="rotate(-10deg)"
              type="button"
              onClick={() => {
                scrollTo('#contact')
              }}
            >
              get in touch
            </ButtonWithMargin>
          </div>
        </div>
        <div>
          <Img
            fluid={data.sanityPicture.picture.asset.fluid}
            alt={data.sanityPicture.altText}
          />
        </div>
      </div>
    </WelcomeStyled>
  )
}

export default Welcome
