import React from 'react'
import styled from 'styled-components'

import Welcome from '../components/Welcome'
import About from '../components/About'
import Contact from '../components/Contact'
import Projects from '../components/Projects'
import SEO from '../components/SEO'

const MainWrapper = styled.div`
  color: var(--grey);
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1100px) minmax(0, 1fr);
  grid-template-rows: 1fr;
  grid-area: main;

  & > div {
    grid-column: 2 / 3;
  }
`

export default function Home() {
  return (
    <>
      <SEO title="Main" />
      <MainWrapper>
        <div>
          <Welcome />
          <Projects />
          <About />
          <Contact />
        </div>
      </MainWrapper>
    </>
  )
}
