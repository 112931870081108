import React from 'react'
import styled from 'styled-components'
import {
  FaDocker,
  FaReact,
  FaCss3Alt,
  FaHtml5,
  IoLogoIonic,
  SiJavascript,
  SiSass,
  GrGatsbyjs,
  GrGraphQl,
  IoLogoAmplify,
  SiJira,
  SiNextDotJs,
  SiFirebase,
  SiJest,
  SiNetlify,
  IoLogoVercel,
  GiFishingHook,
  FaGitAlt,
  SiKubernetes,
  FcCommandLine,
} from 'react-icons/all'

const ToolsTechnologiesStyles = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2em;
  row-gap: 1em;
  color: var(--darker-grey);

  .js {
    color: #f7df1e;
  }
  .html {
    color: #e34c26;
  }
  .css {
    color: #264de4;
  }
  .react {
    color: #61dbfb;
  }
  .ionic {
    color: #498aff;
  }
  .sass {
    color: #cd6799;
  }
  .gatsby {
    color: #663399;
  }
  .graphql {
    color: #e535ab;
  }
  .amplify {
    color: #ff9900;
  }
  .firebase {
    color: #ffca28;
  }
  .git {
    color: #f34f29;
  }
  .jira {
    color: #0052cc;
  }
  .jest {
    color: #1bbf15;
  }
  .docker {
    color: #0db7ed;
  }
  .k8s {
    color: #3068d9;
  }
  .netlify {
    color: #00ad9f;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: minmax(0, 1fr);
    text-align: center;
  }
`

function ToolsTechnologies() {
  return (
    <>
      <ToolsTechnologiesStyles>
        <li>
          Vanilla Javascript <SiJavascript className="js" />
        </li>
        <li>
          HTML <FaHtml5 className="html" /> and CSS{' '}
          <FaCss3Alt className="css" />
        </li>
        <li>
          React.js <FaReact className="react" /> with React Hooks{' '}
          <GiFishingHook className="react" />
        </li>
        <li>
          Ionic <IoLogoIonic className="ionic" />
        </li>
        <li>
          Styled Components 💅, Sass <SiSass className="sass" />
        </li>
        <li>
          Next.js <SiNextDotJs />
        </li>
        <li>
          Gatsby <GrGatsbyjs className="gatsby" />
        </li>
        <li>Sanity CMS</li>
        <li>
          GraphQL <GrGraphQl className="graphql" />
        </li>
        <li>
          AWS Amplify <IoLogoAmplify className="amplify" />
        </li>
        <li>
          Netlify <SiNetlify className="netlify" />, Vercel <IoLogoVercel />
        </li>
        {/* <li>
          Firebase <SiFirebase className="firebase" (in progress) />
        </li> */}
        <li>
          Jest <SiJest className="jest" />
        </li>
        <li>React Testing Library 🐐</li>
        <li>Cypress</li>
        <li>
          CLI basics (bash) <FcCommandLine className="cli" />
        </li>
        <li>
          Git <FaGitAlt className="git" />, Jira <SiJira className="jira" />
        </li>
        <li>
          Docker <FaDocker className="docker" />, Kubernetes{' '}
          <SiKubernetes className="k8s" />
        </li>
      </ToolsTechnologiesStyles>
    </>
  )
}

export default ToolsTechnologies
