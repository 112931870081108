import React from 'react'
import styled from 'styled-components'
import ReactCountryFlag from 'react-country-flag'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import cv from '../assets/documents/ElisabethLeonhardt.pdf'
import ToolsTechnologies from './ToolsTechnologies'
import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'
import { ButtonComponentStyles } from '../styles/GenericStyles'

const AboutStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: 2em 0;

  // changing color of the dots because in the library, they are transparent
  .slider {
    .slick-dots {
      padding: 0.5em;
      li button:before {
        color: var(--grey);
      }
    }
  }

  @media (max-width: 1150px) {
    padding: 0 2em;
  }

  @media (max-width: 550px) {
    text-align: center;
  }
`
const CertificateCardStyles = styled.div`
  margin: 1em;
  h3 {
    color: var(--lime);
  }
  span {
    color: var(--darker-grey);
  }
`

function CertificateCard({ certificate }) {
  const { date, description, id, title, image } = certificate
  const styles = {
    height: '20em',
    width: '100%',
    objectFit: 'cover',
  }
  return (
    <CertificateCardStyles key={id}>
      <Img fluid={image.asset.fluid} alt={title} style={styles} />
      <h3>{title}</h3>
      <span>{date}</span>
      <p>{description}</p>
    </CertificateCardStyles>
  )
}

CertificateCard.propTypes = {
  certificate: PropTypes.shape({
    date: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
  }),
}

const AboutMeStyles = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;

  li:last-child {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-size: 1.3rem;
    text-align: justify;
    margin-bottom: 2em;
  }

  img {
    width: 100%;
    height: 30em;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 768px) {
    grid-template-columns: minmax(0px, 1fr);
    text-align: center;
  }
`

function AboutMe({ picture }) {
  return (
    <AboutMeStyles>
      <li>
        <Img fluid={picture.picture.asset.fluid} alt={picture.altText} />
      </li>
      <li>
        <p>
          I am a frontend developer from Germany, based in Córdoba, Argentina. I
          work with love for detail and aspire to exceed expectations with what
          I do.
        </p>
        <ButtonComponentStyles as="a" href={cv} download>
          Download my resume
        </ButtonComponentStyles>
      </li>
    </AboutMeStyles>
  )
}

AboutMe.propTypes = {
  picture: PropTypes.object.isRequired,
}

const ExperienceStyles = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
  span {
    color: var(--lime);
  }
  p {
    color: var(--darker-grey);
  }

  @media (max-width: 768px) {
    grid-template-columns: minmax(0px, 1fr);
    text-align: center;
    padding: 0 1em;
  }
`

function Experience(experience) {
  const items = experience.items.nodes
  return (
    <ExperienceStyles>
      {items.map(({ role, company, timerange, description, id }) => (
        <li key={id}>
          <span>{role}</span>
          <p>{company}</p>
          <p>{timerange}</p>
          <p>{description}</p>
        </li>
      ))}
    </ExperienceStyles>
  )
}

const LanguagesStyles = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2em;

  span {
    color: var(--lime);
  }
  p {
    color: var(--darker-grey);
  }

  li:last-child span p {
    text-decoration: line-through;
    color: var(--lime);
    display: inline;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }

  @media (max-width: 550px) {
    grid-template-columns: minmax(0, 1fr);
  }
`

function Languages() {
  return (
    <LanguagesStyles>
      <li>
        <span>
          German <ReactCountryFlag countryCode="DE" />
        </span>
        <p>
          My mother tounge. Useful for angry shouting and long complicated
          words.
        </p>
      </li>
      <li>
        <span>
          English <ReactCountryFlag countryCode="US" />/
          <ReactCountryFlag countryCode="GB" />
        </span>
        <p>
          The language I dream in after reading documentation. I also used it to
          communicate with teams and clients when working for DinoCloud and
          B-Yond.
        </p>
      </li>
      <li>
        <span>
          Spanish <ReactCountryFlag countryCode="AR" />
        </span>
        <p>
          I live in Argentina for around 10 years now. A slight argentinan
          dialect might be noticable. 😄
        </p>
      </li>
      <li>
        <span>
          <p>French</p> <ReactCountryFlag countryCode="FR" />
        </span>
        <p>
          The language I always loved and never learned. Although talking in
          french will not be possible, I appreciate the excellent bakery 🥐.
        </p>
      </li>
    </LanguagesStyles>
  )
}

const EducationStyles = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2em;
  align-items: end;
  li:last-child {
    grid-column: 2 / -1;
  }
  img {
    width: 100%;
  }

  span {
    color: var(--lime);
  }

  .gatsby-image-wrapper {
    box-shadow: 0px 0px 15px 5px var(--darker-grey);
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    text-align: center;
    img {
      width: 50%;
    }
    li:first-child {
      display: flex;
      justify-content: center;
    }
    li:last-child {
      grid-column: initial;
    }
  }
`

function Education({ logo }) {
  return (
    <EducationStyles>
      <li>
        <Img fluid={logo.picture.asset.fluid} alt={logo.altText} />
      </li>
      <li>
        <span>2019 BA in Computer Engineering</span>
        <p>
          Focus on development for embedded systems, networking and computer
          arquitecture.
        </p>
      </li>
    </EducationStyles>
  )
}

Education.propTypes = {
  logo: PropTypes.object.isRequired,
}

function Certificates(certificates) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplayspeed: 500,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  return (
    // its a lot of props, so I am disabling eslint here
    // eslint-disable-next-line
    <Slider {...settings} className="slider">
      {certificates.certificates.nodes.map(certificate => (
        <CertificateCard certificate={certificate} key={certificate.id} />
      ))}
    </Slider>
  )
}

function About() {
  const data = useStaticQuery(graphql`
    query {
      experience: allSanityExperience(sort: { fields: index, order: DESC }) {
        nodes {
          company
          description
          role
          timerange
          id
        }
      }
      # certificates: allSanityCertificate(sort: { fields: index, order: ASC }) {
      #   nodes {
      #     date
      #     description
      #     id
      #     title
      #     image {
      #       asset {
      #         fluid(maxWidth: 1000) {
      #           ...GatsbySanityImageFluid
      #         }
      #       }
      #     }
      #   }
      # }
      me: sanityPicture(id: { eq: "-5d5515fb-cc2e-50cf-a3ea-0aaeb37415d3" }) {
        altText
        picture {
          asset {
            fluid(maxWidth: 800, maxHeight: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      unc: sanityPicture(id: { eq: "-95e7b600-33fc-5c61-9819-0bdf8ff93a16" }) {
        altText
        picture {
          asset {
            fluid(maxWidth: 1000, maxHeight: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)
  return (
    <AboutStyled id="about">
      <h1>About me</h1>
      <AboutMe picture={data.me} />
      <h2>Tools and Technologies</h2>
      <ToolsTechnologies />
      <h2>Experience</h2>
      <Experience items={data.experience} />
      <h2>Languages</h2>
      <Languages />
      <h2>Education</h2>
      <Education logo={data.unc} />
      {/* <h2>courses/certificates</h2>
      <Certificates certificates={data.certificates} /> */}
    </AboutStyled>
  )
}

export default About
