import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FaGitlab, IoLogoVercel } from 'react-icons/all'

const ProjectsStyled = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5em;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    grid-column: 1 / -1;
    padding: 0;
    margin: 0;
    height: 2em;
  }

  @media (max-width: 1150px) {
    margin: 0 2em;
  }

  @media (max-width: 768px) {
    grid-template-columns: minmax(200px, 1fr);
  }
`
const ProjectCardStyles = styled.div`
  overflow: hidden;

  .project {
    position: relative;
    height: 16em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  h1,
  p,
  ul {
    position: relative;
  }

  p {
    padding: 0 1.5em;
  }

  h3,
  p {
    transform: translateY(-12em);
    transition: 500ms;
  }

  ul {
    display: flex;
    gap: 1em;
    transform: translateY(12em);
    transition: 300ms;
  }

  a {
    color: var(--lime);
    cursor: pointer;
    text-decoration: none;
  }

  .project:hover {
    scale: 1.1;
    transition: all 300ms;

    h3,
    p {
      transform: translateY(0);
      transition: 500ms 100ms;
      color: white;
      z-index: 4;
    }
    ul {
      transform: translateY(0);
      transition: all 500ms 100ms;
      z-index: 4;

      li:hover {
        transform: rotate(-7deg);
      }
    }
  }

  .project:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dark);
    opacity: 0;
    position: absolute;
    transition: opacity 300ms ease-in-out;
    z-index: 3;
  }

  .project:hover:before {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    h3,
    p,
    ul {
      transform: translateY(0);
      z-index: 4;
    }

    .project {
      scale: 1.1;
    }

    .project:before {
      opacity: 0.7;
    }
  }
`

function SingleProject({ project }) {
  const { name, technologies, repoUrl, projectUrl, image, slug } = project
  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100%',
    objectFit: 'cover',
    height: '100%',
    overflow: 'none',
  }
  return (
    <ProjectCardStyles className="project-wrapper">
      <div className="project">
        <Img fluid={image.asset.fluid} alt={name} style={style} />
        <h3>{name}</h3>
        <p>{technologies}</p>
        <ul>
          <li>
            <Link
              to={`/projects/${slug.current}`}
              title="get more details about the project"
            >
              Learn more
            </Link>
          </li>
          {repoUrl !== null && (
            <li>
              <a
                href={repoUrl}
                title="see my code"
                target="_blank"
                rel="noreferrer"
              >
                <FaGitlab />
              </a>
            </li>
          )}
          <li>
            <a
              href={projectUrl}
              title="see this app deployed"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoVercel />{' '}
            </a>
          </li>
        </ul>
      </div>
    </ProjectCardStyles>
  )
}

SingleProject.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    technologies: PropTypes.string.isRequired,
    repoUrl: PropTypes.string,
    projectUrl: PropTypes.string,
    image: PropTypes.object.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }),
  }),
}

function Projects() {
  const data = useStaticQuery(graphql`
    query {
      projects: allSanityProject {
        nodes {
          name
          projectUrl
          repoUrl
          technologies
          id
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ProjectsStyled id="projects">
      <h1>Have a look at my latest projects:</h1>
      {data.projects.nodes.map(project => (
        <SingleProject project={project} key={project.id} />
      ))}
    </ProjectsStyled>
  )
}

export default Projects
